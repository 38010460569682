// Custom items go here
$primary: #2c7be5;
$soft-primary: #d5e5fa;
$border-color: #d2ddec;
$text-gray: #b1c2d9;

// Until https://github.com/reactstrap/reactstrap/issues/2431 is fixed,
// an unintended mb-3 that you can't remove is added to all form groups
// via Reactstrap. This is a workaround.
.form-group.mb-3 {
  margin-bottom: 1.375rem !important;
}

// override for other mb's needed due to the above bug;
// these matches dashkit's default
.form-group.mb-0 {
  margin-bottom: 0 !important;
}
.form-group.mb-1 {
  margin-bottom: 0.1875rem !important;
}
.form-group.mb-2 {
  margin-bottom: 0.375rem !important;
}
.form-group.mb-4 {
  margin-bottom: 1.5rem !important;
}
.form-group.mb-5 {
  margin-bottom: 2.25rem !important;
}
.form-group.mb-n1 {
  margin-bottom: -0.1875rem !important;
}
.form-group.mb-n2 {
  margin-bottom: -0.375rem !important;
}
.form-group.mb-n3 {
  margin-bottom: -0.75rem !important;
}
.form-group.mb-n4 {
  margin-bottom: -1.5rem !important;
}

// Dashkit overrides (for v2.1.0)
.form-check-input:not(.form-switch .form-check-input)  {
  // fixes default style of checkboxes and radio buttons
  // which had no border and thus too subtle
  appearance: auto;
}

// For organization feature toggles
.superuser-feature {
  border: 1px solid #f8d7da;
  background-color: #e6effc;
  border-radius: 5px;
}

// remove underline from anything with btn class
.btn:hover, .nav-link:hover {
  text-decoration: none;
}

// use older soft colors which are lighter
.bg-primary-soft {
  background-color: #e6effc !important;
}

.bg-secondary-soft {
  background-color: #eef0f4 !important;
}

.bg-success-soft {
  background-color: #e0faf0 !important;
}

.bg-info-soft {
  background-color: #e7f5f9 !important;
}

.bg-warning-soft {
  background-color: #fef8e8 !important;
}

.bg-danger-soft {
  background-color: #fce7eb !important;
}

.bg-light-soft {
  background-color: #fdfdfe !important;
}

.bg-dark-soft {
  background-color: #e3e5e8 !important;
}

.bg-white-soft {
  background-color: white !important;
}

// Add Dashkit larger container to allow more horizontal space
@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1340px;
  }
}

// To ensure that long urls don't cause columns to go too large
body {
  word-break: break-word;
}

// global default (mainly needed for autosize textareas)
textarea {
  width: 100%;
}

// inputs of type number should not show up/down arrows within the widget
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// prevent very wide tooltips on desktop
@media (min-width: 576px) {
  .popover {
    max-width: 20rem;
  }
}

// Larger popover for bigger content https://github.com/reactstrap/reactstrap/issues/449
.popover-lg .popover {
  max-width: 30rem;
}

// Larger popover for bigger content https://github.com/reactstrap/reactstrap/issues/449
.popover-xxl .popover {
  max-width: none;
}

// show invalid feedback items in modals (override Dashkit's hiding of it)
.invalid-feedback {
  display: block !important;
  margin-top: 0.25rem;
}

// better looking modals that are bootstrap-friendly
.modal-header {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.25rem;
}

h5.modal-title {
  flex: 1 1;
  margin-bottom: 0;
  letter-spacing: -0.02em;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1;
}

// improve header subtitle from Dashkit
.header-subtitle {
  margin-top: 0;
}

/* Froala tweaks */
form .fr-wrapper {
  // this is needed to prevent dropdowns that sit/open above
  // a froala editor to show underneath the froala editor itself,
  // e.g. job code dropdown in approval dialog for promo packets
  // that sits above a froala comments editor
  z-index: auto !important;
}

.fr-popup {
  // modals are z-index 1050, so to be safe and ensure
  // that when editing happens in a modal that, for example,
  // link editors show on top, put z-index to 9999
  z-index: 9999 !important;
}

.fr-box.fr-basic .fr-element a {
  color: $primary;
}

// round bottom bar
.fr-box.fr-basic .fr-wrapper {
  border-radius: 0 0 10px 10px !important;
}

/* make the editor take the full padding of the card
   (for when it's directly in a card, e.g. activity page text editor)
*/
.card .card-body > .row > .col > .fr-box.fr-basic {
  margin: -1.5rem;
}

/* remove borders from the editor */
.fr-toolbar.fr-top {
  border: none;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
  border: none;
}

/* make the editor text look like the rest of the page */
.fr-box.fr-basic .fr-element.fr-view {
  font-size: 0.9375rem;
  font-family: 'Cerebri Sans', sans-serif;
  color: #12263f;
}

/* use our shade of gray on the line separating the toolbar from the editor */
.fr-toolbar .fr-newline {
  display: none !important;
}

/* needed for Bootstrap 5+ to prevent duplicated border line */
form .fr-toolbar.fr-top.fr-basic {
  border-bottom: none;
}

/* show full-width bar below toolbar in non-form on-page editors, e.g. activity editor */
.fr-toolbar.fr-top.fr-basic {
  border-bottom: 1px solid #edf2f9;
}

// inline editor within a set of forms (e.g. in popup)
.mention-editor .fr-toolbar {
  display: none;
}

.mention-editor.fr-box,
.mention-editor .fr-element.fr-view,
.mention-editor.fr-box.fr-basic.fr-top .fr-wrapper {
  border-radius: 10px !important;
}

.fr-box .fr-second-toolbar {
  border: none !important;
  padding: 0.5rem 0 0 0;
  // to not obscure "A description is required" and other invalidation messages
  background: inherit;
}

/* Froala (as input within form) */
form .fr-box.fr-basic {
  margin: 0;
}

form .form-group .mention-editor.fr-box,
form .form-group .mention-editor.fr-box.fr-basic.fr-top .fr-wrapper,
form .form-group .mention-editor .fr-element.fr-view {
  border-radius: 0.375rem !important;
}

form .fr-box.fr-basic.fr-top .fr-wrapper,
form .fr-toolbar.fr-top {
  border: 1px solid #d2ddec;
}

form .fr-box.fr-basic .fr-element {
  padding: 0.5rem 0.75rem;
  min-height: inherit;
}

.fr-wrapper .fr-placeholder {
  font-family: 'Cerebri Sans', sans-serif !important;
  color: #b1c2d9 !important;
}

.fr-disabled {
  background-color: #e9ecef;
}

form .fr-wrapper .fr-placeholder {
  padding: 0.5rem 0.75rem !important;
}

// when toolbarInline is true (to only show toolbar on hover)
// we need to add back the padding/margin that otherwise
// comes when the toolbar is there
.fr-inline .fr-element.fr-view {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
}

// align char counter when toolbarInline is true to right
.fr-inline .fr-counter {
  padding-right: 0;
}

// for inline mini-text-entries, e.g. 1:1 agenda items and
// feedback request custom question editor, remove border
.fr-inline.hide-border .fr-element.fr-view {
  padding: 0 !important;
  border: none !important;
}

// for inline mini-text-entries, we have hide-border
form .hide-border .fr-wrapper .fr-placeholder {
  padding: 0 !important;
}

form .fr-box.fr-basic,
.fr-view {
  p:last-of-type {
    margin-bottom: 0;
  }
}

form .fr-box .fr-second-toolbar .fr-counter {
  padding: 0;
}

.fr-view p {
  white-space: pre-line;
}

.linebreak {
  white-space: pre-line;
}

// Bootstrap sliders
.slider.slider-horizontal {
  width: 100%;
}

a:hover {
  text-decoration: underline;
}

a.nounderline:hover {
  text-decoration: none;
}

/* ensure that for hierarchical dropdown toggles with btn-sm, the dropdown indicator
   doesn't sit at the top but rather is middle-aligned */
.dropdown-item.dropdown-toggle:after {
  line-height: inherit;
}

// to ensure claim notification dropdown button color doesn't change
.dropdown-menu a:not(.btn):not(.dropdown-item) {
  color: inherit;
}

// for icons, e.g. linkedin, twitter, etc.
.dropdown-menu a.btn-brand {
  color: white;
}

.dropdown-menu a:hover {
  text-decoration: none;
}

.dropdown-menu.dropdown-menu-card .row {
  padding: 0.5rem;
}

// Dashkit disabled inputs don't look disabled, so making them look a bit more disabled
.form-control:disabled,
.form-control[readonly] {
  background-color: $disabled-input-color;
  opacity: 1;
}

// react-tags doesn't have a native "Disabled" look so we provide an outside class
// and manually override styles so it looks more disabled
.disabled > .react-tags,
.disabled > .react-tags > .react-tags__search > .react-tags__search-wrapper > input {
  background-color: $disabled-input-color;
}

.btn-link, .btn-link:hover, .btn-link:focus-visible {
  background-color: unset;
}

.btn-group > .btn:focus-visible:not(.active) {
  background-color: unset;
  color: unset;
}

.focusable-element {
  all: unset;
}

.focusable-element:focus-visible {
  outline: 2px solid $soft-primary !important;
  outline-offset: 2px !important;
}

.object-card-close-button:focus-visible,
.react-tags__selected-tag:focus-visible {
  outline: 2px solid $soft-primary !important;
  outline-offset: 2px !important;
}

input[type="radio"]:not(:checked):focus-visible {
  outline: 2px solid $soft-primary !important;
  outline-offset: 2px !important;
}

// for showing confetti on thank you page
.react-dom-confetti-overlay {
  position: relative;
  top: 50%;
  left: 50%;
}

li.tag-cloud-tag {
  margin-bottom: 0.5625rem;
}

// add slight separation visuals for tag cloud tags
li.tag-cloud-tag:not(:last-child) {
  border-right: 1px solid #0000000a;
  padding-right: 0.5em;
}

// Leveling product overrides
.container.kanban-container {
  min-height: inherit;
}

// make sure dropdown menus don't look too short, but also let them expand for long items within them
.dropdown-menu {
  min-width: 100%;
  outline: none; // Dropdown card outlines when clicked but should not
}

// redeclare width for dropdown menu cards as it got overridden via the above declaration
@media (min-width: 576px) {
  .dropdown-menu-card {
    min-width: 350px;
  }
}

// react-autosuggest -- have selecting in it match react tags autocomplete
.dropdown-item.active,
.dropdown-item:active {
  background: $soft-primary;
  text-decoration: none;
  cursor: pointer;
}

// leveling setup nav
.setup-nav {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.setup-nav .header-pretitle {
  margin-bottom: 1rem;
}

.setup-nav > .setup-nav-section > ol {
  padding-inline-start: 1rem;
  padding-left: 0;
  margin-bottom: 2rem;
}

.setup-nav > .setup-nav-section > ol > li {
  counter-increment: setup-nav-counter;
  margin-bottom: 0.8rem;
  list-style: none inside;
  overflow: hidden;
}

.setup-nav > .setup-nav-section > ol > li:before {
  content: counter(setup-nav-counter);
  margin-right: 0.5rem;
  margin-bottom: 0.01rem;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 1.4rem;
  background: #95aac9;
  color: #fff;
  text-align: center;
  float: left;
}

// skills matrix
.skills-matrix {
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.skills-matrix-header-cell {
  position: sticky;
  top: 0;
  vertical-align: top;
  width: 200px;
  z-index: 1;
  background-color: #f9fbfd;
  box-shadow: 0px -1px 0px #b3bece inset;
}

.skills-matrix-header-cell-corner {
  left: 0;
  z-index: 2;
}

.skills-matrix-row {
  border-bottom: 1px solid #b3bece;
}

.skills-matrix-row-category {
  background-color: #f9fbfd;
  left: 0;
  position: sticky;
  vertical-align: top;
  border-right: none !important;
  box-shadow: -1px 0px 0px #edf2f9 inset;
}

.skills-matrix-row-cell {
  border-right: 1px solid #edf2f9;
  vertical-align: top;
}

.skills-matrix-header-item,
.skills-matrix-row-item {
  padding: 0.75rem;
}

.skills-matrix-row-cell.level-input {
  cursor: pointer;
}

.skills-matrix-row-cell.level-input:hover {
  background-color: #f9fbfd;
}

.skills-matrix-row-cell.level-input.selected-level {
  background-color: #edf2f9;
}

.skills-matrix-row-cell.level-input.selected-level:hover {
  background-color: #e3ebf6;
}

/* leveling career paths */

.leveling-career-paths {
  table {
    table-layout: fixed;
    width: 100%;
    font-size: 0.8125rem;
  }

  table tr {
    border-bottom: 1px solid #edf2f9;
  }

  table th {
    padding: 0.75rem 1rem;
    background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.08em;
    color: #95aac9;
  }

  table th:first-child {
    border-right: 1px solid #edf2f9;
    width: 65px;
  }

  table td {
    padding: 0.75rem 1rem;
    vertical-align: top;
    border-left: 1px solid #edf2f9;
  }

  table td:first-child {
    font-weight: bold;
    border-right: 1px solid #edf2f9;
    border-left: none;
    background-color: #f9fbfd;
  }

  table .empty-cell {
    background-color: #f9fbfd;
  }
}

/* custom widgets */

.more-dropdown-button {
  border: none;
  margin: -7px 0 0 0;
  padding: 2px 0 0 0;
  border-radius: 50%;
  width: 1.5rem;
}

.more-dropdown-button:hover {
  background-color: #e8edf3;
  box-shadow: none;
}

/* activities custom input */

.comment-body {
  background-color: #f5f6fa;
  border-radius: 0.5rem;
  // prevent long words overflowing, e.g. in Perf Admin with very long words in comments
  // (for some reason declaring this on body doesn't apply here, maybe Chrome bug?)
  word-break: break-word;
}

.activity-input {
  background-color: #f5f6fa;
  border-radius: 0.5rem;
  color: #71839e;
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  transition: all 0.1s ease-in-out;
}

.activity-input:hover {
  background-color: #e8edf3;
}

.add-to-activity {
  border: 1px solid #e3ebf6;
  border-radius: 0.5rem;
  padding: 1rem;
}

.add-to-activity span {
  font-size: 1.5rem;
}

.title-input {
  border: none;
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 1.1;
  margin: 0;
  padding: 0;
}

.small-input {
  font-size: 0.8125rem;
  height: 1.15rem;
  color: #95aac9;
}

/* for hiding content that runs too long */
.collapse-content-overlay {
  box-shadow: inset 0px -88px 30px 0 white;
  border-radius: 0 0 0.5rem 0.5rem;
  bottom: 0;
  left: -0.75rem;
  padding: 4rem 0 0;
  position: absolute;
  right: -0.75rem;
  text-align: center;
  z-index: 1;
}

/* activity contributions editor */
.avatar-nav {
  margin: -1.5rem -1.5rem 1rem;
  padding: 1rem 1.5rem 0rem 1rem;
  background-color: #f9fbfd;
  border-bottom: 1px solid #e3ebf6;
}

.avatar-nav li {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  margin: 0 0 -1px;
  padding: 0.65rem 0.55rem;
}

.avatar-nav li:hover {
  border-color: #e3ebf6;
  border-bottom-color: transparent;
}

.avatar-nav li.active {
  background-color: white;
  border-color: #e3ebf6;
  border-bottom-color: white;
  cursor: default;
}

.avatar-complete {
  position: relative;
}

.avatar-complete:before {
  background-color: #00d97e;
  border-radius: 10rem;
  color: white;
  content: '\e92b';
  font-family: 'Feather', sans-serif;
  font-size: 0.75rem;
  height: 1rem;
  position: absolute;
  text-align: center;
  top: -0.25rem;
  right: -0.25rem;
  width: 1rem;
}

/* like buttons for activities */
.like-button {
  background-color: #edf2f9;
  border: 1px solid #edf2f9;
  border-radius: 100rem;
  color: #283e59;
  cursor: pointer;
  display: inline-block;
  font-size: .8125rem;
  font-weight: 400;
  margin-right: .375rem;
  padding: .2rem .5rem;
  transition: all .1s ease-in-out;
  vertical-align: top;
}

.like-button:hover {
  transform: scale(1.2);
}

.like-button-active {
  background-color: #e8f2ff;
  border-color: #2c7be5;
  color: #2c7be5;
}

/* hierarchy lists */

/* basic list styles */
.hierarchy-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.hierarchy-list .hierarchy-list-item .hierarchy-list-item-content {
  padding: 0.75rem 0;
  position: relative;
}

/* this indents lists that are children of other lists */
.hierarchy-list .hierarchy-list {
  margin-left: 1.6rem;
}

/* nested list lines */
/* this is a really ugly selector. the reason we use the direct child selector is that we only want to select the heirarchy lists that have another hierarchy list as a parent. if the hierarchy list does not have another hierarchy list as a parent, that means it's the first element in the tree and should not have any list lines. */
.hierarchy-list-item > .hierarchy-list .hierarchy-list-item-content:before {
  background-color: #c0c9d6;
  content: '';
  height: 100%;
  left: -0.4rem;
  position: absolute;
  top: -2rem;
  width: 1px;
}

.hierarchy-list-item > .hierarchy-list .hierarchy-list-item-content:after {
  background-color: #c0c9d6;
  content: '';
  height: 1px;
  left: -0.4rem;
  position: absolute;
  top: 2rem;
  width: 0.4rem;
}

/* we have to give avatars a z-index so the lines will go underneath avatars */
.hierarchy-list .avatar {
  z-index: 1;
}

/* left nav: ensure that things don't appear underneath at first when fading
   in by overriding the default z-index in the theme of 1030 to auto */
.navbar[class*=fixed-] {
  z-index: auto;
}

/* top nav search (to be mobile-friendly) - Bootstrap breakpoints below */

// Small devices (landscape phones, 576px and up)
.navbar-search {
  width: 170px;
}

@media (min-width: 576px) {
  .navbar-search {
    width: auto;
  }
}

/* Smaller avatars */
.avatar-xxs {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.5rem;
}

.avatar-xxs .avatar-img,
.avatar-xxs .avatar-title {
  position: relative;
  top: 1px;
}

.avatar-group .avatar-xxs .avatar-title {
  top: 0;
}

.avatar-group .avatar-xxs + .avatar-xxs {
  margin-left: -0.3rem;
}

/* Avatar groups in circular form (a la Facebook Messenger) per https://codepen.io/gil00pita/pen/akjgJR*/

.circular-avatar {
  border-radius: 10em;
  position: absolute;
  top: 0.6em;
  left: 0.6em;
  width: 2.8em;
  height: 2.8em;
}

.circular-avatar-xs {
  border-radius: 10em;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 2em;
  height: 2em;
}

.circular-avatar-group-member-wrapper,
.circular-avatar-group-member {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.circular-avatar-group-member-wrapper {
  overflow: hidden;
}
.circular-avatar-group {
  top: 0;
  left: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.circular-avatar-group[data-number-of-thumbnails='2']
  .circular-avatar-group-member-wrapper:first-child {
  left: -25%;
}
.circular-avatar-group[data-number-of-thumbnails='2']
  .circular-avatar-group-member-wrapper:nth-child(2) {
  left: 50%;
}
.circular-avatar-group[data-number-of-thumbnails='2']
  .circular-avatar-group-member-wrapper:nth-child(2)
  .circular-avatar-group-member {
  left: -25%;
}
.circular-avatar-group[data-number-of-thumbnails='3']
  .circular-avatar-group-member-wrapper:first-child {
  left: -25%;
}
.circular-avatar-group[data-number-of-thumbnails='3']
  .circular-avatar-group-member-wrapper:nth-child(2),
.circular-avatar-group[data-number-of-thumbnails='3']
  .circular-avatar-group-member-wrapper:nth-child(3) {
  left: 50%;
  width: 50%;
  height: 50%;
}
.circular-avatar-group[data-number-of-thumbnails='3']
  .circular-avatar-group-member-wrapper:nth-child(3) {
  top: 50%;
}
.circular-avatar-group[data-number-of-thumbnails='4']
  .circular-avatar-group-member-wrapper {
  width: 50%;
  height: 50%;
}
.circular-avatar-group[data-number-of-thumbnails='4']
  .circular-avatar-group-member-wrapper:nth-child(2),
.circular-avatar-group[data-number-of-thumbnails='4']
  .circular-avatar-group-member-wrapper:nth-child(4) {
  left: 50%;
}
.circular-avatar-group[data-number-of-thumbnails='4']
  .circular-avatar-group-member-wrapper:nth-child(3),
.circular-avatar-group[data-number-of-thumbnails='4']
  .circular-avatar-group-member-wrapper:nth-child(4) {
  top: 50%;
}

/* highlights */
.highlight-circle {
  box-shadow: 0 1px 5px rgba(0,0,10,.05);
}

.highlight-circle:disabled {
  opacity: 1.0; // override .disabled opacity
}

/* emoji reactions bar */
.action-container {
  margin-top: .25rem;
  margin-bottom: 1rem;
}

.emoji-popover {
  max-width: 30rem !important;
  padding: 0;
  border: none;
}

.reaction-bar {
  padding: 0;
  vertical-align: -3px;
}

.reaction-bar > li.like-button {
  font-size: .8125rem;
  font-weight: 400;
  vertical-align: top;
}

.reaction-bar > li.like-button-active {
  background-color: #E8F2FF;
  border-color: #2C7BE5;
  color: #2C7BE5;
}

.reaction-bar > li {
  align-items: center;
  background-color: #edf2f9;
  border: 1px solid transparent;
  border-radius: 10rem;
  color: #12263F;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.1rem;
  height: 1.7rem;
  line-height: 1;
  margin: 0 .3rem .25rem 0;
  padding: 0 .6rem;
  transition: all .1s ease-in-out;
  white-space: nowrap;
}

.reaction-bar-large > li {
  font-size: 1.4rem;
  height: 2.4rem;
  padding: 0 0.5rem;
}

.reaction-bar-large > li:hover {
  transform: scale(1.4);
}

.reaction-bar-small > li {
  font-size: .7rem;
  height: 1.2rem;
  padding: 0 .25rem;
}

.reaction-bar-small > li:hover {
  transform: scale(1.4);
}

.reaction-bar .reaction-count {
  font-size: .65rem;
  font-weight: 700;
}

.reaction-bar .reaction-bar-added {
  background-color: #E8F2FF;
  border-color: #2C7BE5;
  color: #2C7BE5;
}

.reaction-bar > li:hover {
  box-shadow: 0 1px 5px rgba(0,0,10,.05);
  transform: scale(1.2);
}

.reaction-bar .reaction-bar-new {
  position: relative;
  top: -2px;
}

.reaction-bar.reaction-bar-small .reaction-bar-new {
  position: relative;
  top: 1px;
}

.reaction-bar .reaction-bar-new img {
  height: 1rem;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer !important;
}

/* filepond file and image uploader */
.filepond--root {
  margin-bottom: 0 !important;
  cursor: pointer !important;
}

.filepond--root div,
.filepond--root label {
  cursor: pointer !important;
}

.filepond--panel-root {
  background-color: transparent !important;
}

.filepond--credits {
  display: none;
}

.filepond--wrapper {
  border-style: dashed;
  padding: 2rem 0 !important;
  background-color: #ffffff !important;
  border: 1px dashed #d2ddec !important;
  border-radius: 0.375rem !important;
  text-align: center !important;
  color: #95aac9 !important;
  transition: all 0.2s ease-in-out !important;
  order: -1 !important;
  cursor: pointer !important;
  z-index: 999 !important;
}

.filepond--label-action {
  text-decoration: none !important;
}

/* image gallery (for activity cards) */
.image-gallery-content:not(.fullscreen) .image-gallery-image {
  //max-height: 30rem !important;
  width: 100%;
  max-height: none;
}

.image-gallery-content .image-gallery-image {
  cursor: pointer;
}

/* locked content in contribution comments */
.blurred-content {
  filter: blur(5px);
  opacity: 0.5;
  pointer-events: none;
}

.blurred-content-overlay {
  z-index: 1; // to ensure clicks don't go to elements behind
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 5.5rem;
  right: 0;
  top: 6.5rem;
  bottom: 0;
  text-align: center;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 90%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 90%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.people-picker .avatar img {
  transition: all 0.15s ease-in-out;
}

.people-picker .avatar:hover img,
.people-picker .avatar:hover span.avatar-title {
  box-shadow: 0 0.25rem 1.5rem rgba(18, 38, 63, 0.25);
  transform: scale(1.1);
}

/* Toasts / Toastify */

/* container */
.Toastify__toast-container {
  color: white;
  font-weight: 500;
  bottom: 1em;
  right: 2em;
}

/* toastify element styles */
.Toastify__toast {
  min-height: 0;
  padding: 12px 16px;
  border-radius: 0.375rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.1);
  font-family: 'Cerebri Sans', sans-serif;
  letter-spacing: -0.02em;
}
.Toastify__toast-body {
  position: relative;
  z-index: 9999;
}
.Toastify__close-button {
  display: none;
}
.Toastify__progress-bar {
  background-color: rgba(0, 0, 0, 0.03);
  bottom: 0px;
  border-radius: 0 0.375rem 0.375rem 0;
  height: auto;
  left: auto;
  right: 0;
  transform-origin: right;
  top: 0;
}

/* colors for different types of messages */
.Toastify__toast--success {
  background: #00d97e;
}
.Toastify__toast--warning {
  background: #f6c343;
  color: #283e59;
}
.Toastify__toast--error {
  background: #e63757;
}
.Toastify__toast--info {
  background: #39afd1;
}

/* to prevent UIs like objectives from having a huge height in the text area */
textarea.form-control {
  min-height: auto;
}

/* Kanban UI (for aspirations and goals) */
.kanban-add-form .form-control[data-toggle="flatpickr"] {
  // hard-coded width doesn't work for i18n, instead we'll
  // mkae it responsive using col-# class
  width: auto;
}

/* Inline react tag editor examples (that fit placeholder text) */
.goal-inline-react-tag-autocomplete .react-tags__search.is-empty {
  width: 100%;
}

.goal-inline-react-tag-autocomplete .react-tags__search-input {
  margin-left: 0;
  margin-right: 0;
}

/* fix to allow dropdowns within tables (to enable, for example, a dropdown that
   is clicked from within a table that extends options below the table itself) */
.table-responsive {
  overflow: visible;
}

/* Performance pages */
.performance-nav {
  position: sticky;
  top: 1.5rem;
}

.performance-nav-section {
  margin-top: 2rem;
}

.performance-nav-section .header-pretitle {
  margin-bottom: 1rem;
}

.select-peer-card {
  box-shadow: none;
  border-width: 2px;
  border-style: dashed;
  border-color: #e3ebf6;
  cursor: pointer;
}

.checklist-nav {
  list-style:none;
  margin-left:0;
  margin-bottom: 2.5rem;
  padding-left:0;
}

.checklist-nav li {
  color: #6e84a3;
  margin-bottom: .8rem;
  padding-left: 1.75rem;
}

.checklist-nav li .active {
  cursor: pointer;
}

.checklist-nav li .active:hover {
  color: #12263f !important;
}

.checklist-nav li a:hover {
  color: #12263f !important;
  text-decoration: none;
}

.checklist-nav li .fe {
  float: left;
  margin-left: -1.75rem;
  margin-top: 3px;
}

.list-group-item .resume-item-action {
  bottom: 0;
  display: block;
  left: -0.5rem;
  margin-left: -8rem;
  padding-top: 1.4rem;
  position: absolute;
  text-align: right;
  top: 0;
  width: 8rem;
}

.list-group-item .resume-item-action .btn {
  box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.06);
  border-radius: .25rem 1rem 1rem .25rem;
  padding-left: .65rem;
  padding-right: .65rem;
}

.list-group-item .resume-item-action .btn:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #2c7be5;
  content: ' ';
  height: 0;
  position:absolute;
  right: -6px;
  top:26px;
  transition: border-color .15s ease-in-out;
  width: 0;
}

.list-group-item .resume-item-action .btn:hover:before {
  border-left-color: #1a68d1;
}

.list-group-item .resume-item-action .btn-edit-reflections {
  border-color: #E6EFFC;
  background-color: #E6EFFC;
  color: #2C7BE5;
}

.list-group-item .resume-item-action .btn-edit-reflections:before {
  top: 4px;
  border-left-color: #E6EFFC;
}

.list-group-item .resume-item-action .btn-edit-reflections:hover:before {
  border-left-color: #E6EFFC;
}

/* feedback items (e.g. perf review items) in contribution cards */
.feedback-pill {
  align-items: center;
  display: inline-flex;
  padding: .375rem .5rem .375rem .85rem;
  position: relative;
}

/* team overview table */
.team-overview-table td, .team-overview-table th {
  padding: 0.45rem 0.85rem;
}

/* compress height of all top header rows in tables */
.table th {
  padding: 0.45rem 0.85rem;
}

/* tweak spacing of data toggler to center vertically with text */
.table [data-sort]:after {
  vertical-align: middle;
  position: relative;
  top: 1.5px;
}

.table.card-table.team-overview-table [data-sort]::after {
  display: none;
}

.table.card-table.calibration-table [data-sort]::after {
  display: none;
}

/* dashkit overrides to ensure alignment with table
   header ends with contents below them */
.card-table thead th:first-child,
.card-table tbody td:first-child {
  padding-left: 0.85rem !important;
}

.card-table thead th:last-child,
.card-table tbody td:last-child {
  padding-right: 0.85rem !important;
}

/* overrides for triple leaderboard */
.card-table.triple-leaderboard thead th:first-child,
.card-table.triple-leaderboard tbody td:first-child {
  padding-left: 0.5rem !important;
}

.card-table.triple-leaderboard thead th:last-child,
.card-table.triple-leaderboard tbody td:last-child {
  padding-right: 0.5rem !important;
}

/* to show request feedback and hide from resume links only on hover */
.list-group-item .display-inline-on-list-group-item-hover {
  display: none;
}

.list-group-item:hover .display-inline-on-list-group-item-hover {
  display: inline;
}

.main-dashboard .display-inline-on-line-hover {
  display: none;
}

.main-dashboard:hover .display-inline-on-line-hover {
  display: inline;
  cursor: pointer;
}

.dark-grey-on-hover:hover {
  color: #12263F;
}

#analytics-tooltip-subtitle {
  color: grey;
  font-size: 0.9em;
}

/* overrides for related-objectives-overflow-table */
.related-objectives-overflow-table thead th {
  white-space: nowrap;
}

.network-form-group .form-group {
  margin-bottom: 0;
  margin-top: 6px;
}

/* overrides for PerformanceStepAssessSelf */
#add-an-accomplishment-button {
  height: 439px;
  border-color: #edf2f9;
  border-style: dotted;
  border-width: 2px;
  box-shadow: none;
  padding: 0rem 2.5rem;
}

.dotted-line-button {
  all: unset;
  cursor: pointer;
  border-radius: 8px;
}

.dotted-line-button:hover {
  background-color: #edf2f9;
}

.dotted-line-button:focus-visible {
  background-color: $soft-primary;
}

/* overrides for SimpleActivityEditor Card */
.modal-activity-selector .modal-body > .card {
  border: 0;
  border-radius: 0;
}

.list-editor-editable-cell:hover {
  background-color: #f8f8f8;
}

/* make height of pagination-sm match height of small buttons, e.g. on promo packets page */
.pagination-sm .page-link {
  line-height: 1.43rem;
}

.calibration-checklist li {
  list-style-type: '𐄂';
  padding-inline-start: 1ch;
}

// for controlling showing things only on hover (e.g. drag-and-drop handles)
// (note: we use media query here to ensure that on mobile/tablet it always shows
// since hovering isn't possible
@media (hover: hover) {
  .hover-parent .hover-child {
    opacity: 0;
  }

  .hover-parent:hover .hover-child {
    opacity: 1;
  }
}

// this amazing piece of CSS is to remove the word "character" from the counter, as the plugin doesn't allow it to be removed
// the hack consists in reverse the direction of the text, fix a width, and add a word spacing to make the counter as far as possible.
.fr-counter {
  width: 4rem;
  white-space:nowrap;
  overflow:hidden;
  direction: rtl;
  word-spacing: 3rem;
}

// fixing bottom margin in calibration flag popovers
.popover-calibration-flag:last-child {
  margin-bottom: 0;
}

// in Dashkit v2.2.0 upgrade (commit #eca4762c), somehow the link colors for .btn-link were unset
.btn-link {
  color: var(--bs-link-color);
}
.btn-link:hover {
  color: var(--bs-link-hover-color);
}

// letting long tags in the 1:1s accordion sidebar feedback cards wrap
.one-on-ones-sidebar .feedback-card .badge {
  white-space: normal;
}
.accordion-table-header {
  padding: 1rem 1rem;
  background-color: #f9fbfd;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: #95aac9;
  height: 1.5rem;
}

.accordion-table-row {
  padding: 1rem 1rem;
  border-top: 2px solid #f9fbfd;
  font-size: 0.8125rem;
  font-weight: 400;
  align-items: center;
}

.heatmap-header {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: #95aac9;
}

.heatmap-row {
  display: block;
  white-space: nowrap;
  line-height: 1rem;
}

.heatmap-row-cell {
  display: inline-block;
  float: none;
  vertical-align: top;
  height: 100%;
  border-right: 1px solid #e3ebf6;
}

.rotate-45 {
  transform: rotate(-45deg);
}

.chakra-checkbox input {
  clip: auto !important;
  height: auto !important;
  width: auto !important;
}

.chakra-checkbox__control {
  display: none !important;
}

.chakra-modal__footer button:nth-child(2) {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}

.chakra-portal [role="tooltip"] {
  --bs-popover-border-radius: 0.5rem;
  background-color: var(--bs-white);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  color: #000;
  font-family: var(--bs-font-sans-serif);
  font-size: var(--bs-popover-font-size);
  font-style: normal;
  font-weight: normal;
  padding: 0.5rem 0.5rem;

  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: var(--bs-popover-max-width);
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-popover-zindex);
}

.chakra-tooltip__arrow {
  background-color: var(--bs-white) !important;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
}

.rdg-cell-error {
  background-color: #e63757 !important;
  border-color: #e63757 !important;
  color: #fff;
}

.rdg-cell-warning {
  background-color: #FFDB80 !important;
  border-color: #FFDB80 !important;
}

.wide-popover>.popover {
  max-width: 30rem;
}

.left-navbar {
  & .navbar--person {
    color: var(--bs-gray-300) !important;
    --bs-text-opacity: 1;
    color: rgba(237, 242, 249, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;

    &:hover > a {
      color: white !important;
    }
  }

  & .navbar--addperson {
    transition: all 60ms ease;
    padding-left: 0;
    padding-right: 0;

    &:hover > span {
      color: white !important;
    }
  }
}

.final-steps--image {
  max-width: 200px !important;
  margin-left: 18px !important;
}

.objective-list {
  &--item {
    &:focus-within {
      z-index: 100;
      box-shadow: 0 0 3px 1px rgba(var(--bs-primary-rgb),0.35);
    }

    @at-root {
      .home-objective-list-view &:hover {
        background-color: rgba(var(--bs-primary-rgb),0.05) !important;
        transition: all 60ms ease-in;
      }

    }
    & .react-tags__selected-tag {
      display: inline-flex;

      &-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
        vertical-align: baseline;
      }
    }
  }
  &--chevron {
    transition: all 60ms ease-in-out;
    color: var(--bs-gray-400);

    @for $i from 1 through 20 {
      &__#{$i} {
        margin-left: $i * 20px;
      }
    }

    &__hover {
      cursor: pointer;

      @at-root {
        .list-group-item:hover & {
          transform: scale(1.25);
        }

        .home-objective-list-view .list-group-item:hover & {
          transform: scale(1) !important;
        }
      }
    }
  }

  &--linked-badge {
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
      background-color: darken(#e7f5f9, 10%) !important;
    }
  }

  &--btn-item {
    cursor: pointer;

    @for $i from 1 through 20 {
      &__#{$i} {
        padding-left: $i * 20px + 20px;
      }
    }

    &:hover {
      background-color: var(--bs-gray-100) !important;
    }
  }
}

.linked-objective-tag {
  position: relative;
  &--edit-btn {
    display: none !important;
    position: absolute;

  }

  &:hover &--edit-btn {
    display: inline !important;
    top: -2px;
  }
}

.summary-details {
  &--row {
    &:hover {
      cursor: pointer;
      background-color: var(--bs-gray-100);
    }
  }
}

#chart-tooltip {
  // default 0 from dashkit, so doesn't show over modal popover which is 1055 from dashkit
  z-index: 1056 !important;
}

// .btn-outline-success, .btn-outline-success:hover, .btn-outline-success:active, .btn-outline-success:visited {
//   border-color: #8064A2 !important;
//   // color: #8064A2 !important;
//   background-color: #8064A2 !important;
// }

// .btn-success, .btn-success:hover, .btn-success:active, .btn-success:visited {
//   // border-color: #8064A2 !important;
//   // color: #8064A2 !important;
//   // filter: brightness(85%);
// }

// hides the last two wells in the cron builder of the react-cron-generator package
// since those options aren't supported by croniter in the backend.
// the components has no way to customize those options
.nudge-edit .cron_builder .cron_builder_bordering .well:nth-child(3),
.nudge-edit .cron_builder .cron_builder_bordering .well:nth-child(4) {
  display: none;
}
// Add an overaly to the element to make it not editable
tr.read-only-row {
  background-color: rgba(0, 0, 0, 0.05);
  pointer-events: none;
}
