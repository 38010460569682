// better-looking react tags input
/* input field */
.react-tags {
  border: 1px solid $border-color;
  border-radius: 0.375rem;
  line-height: 1.5;
  padding: 0.25rem 0.25rem 0;
}

.react-tags.is-focused {
  border-color: $primary;
}

/* ensure text isn't cut off and center vertically */
.react-tags__search-input {
  min-width: 100%;
  margin: 0.25rem 0.35rem 0.5rem 0.4rem;
}

/* input field placeholder text */
.react-tags__search-input::-webkit-input-placeholder {
  color: $text-gray;
  opacity: 1;
}

.react-tags__search-input::-moz-placeholder {
  color: $text-gray;
  opacity: 1;
}

.react-tags__search-input:-ms-input-placeholder {
  color: $text-gray;
  opacity: 1;
}

.react-tags__search-input::-ms-input-placeholder {
  color: $text-gray;
  opacity: 1;
}

.react-tags__search-input::placeholder {
  color: $text-gray;
  opacity: 1;
}

/* tags */
.react-tags__selected-tag {
  color: $primary;
  background-color: $soft-primary;
  border: none;
  border-radius: 10rem;
  font-size: 0.75rem;
  padding: 0.45em 0.75em;
  vertical-align: top;
}

.react-tags__selected-tag:after {
  color: $primary;
}

.card-header .react-tags {
  border: none;
  padding-left: 0;
}

.card-header .react-tags .react-tags__search {
  padding-left: 0;
}

.react-tags__search {
  max-width: inherit;
  padding: 0;
  margin: 0;
}

// ensure suggestions overlay appears on top of things
.react-tags .react-tags__suggestions {
  min-width: 300px;
  z-index: 1000;
  width: auto;
}

// remove weird whitespace in dropdown for react tags autocomplete
.react-tags .react-tags__suggestions li mark {
  padding: 0;
}

/* set the line height to the rest of the page */
.react-tags {
  line-height: 1.5;
}

/* set border radius and background to the dashkit colors/radii */
.react-tags__selected-tag {
  border-radius: 0.375rem;
  background: #f9fbfd;
}

/* make the dropdown wider */
@media screen and (min-width: 30em) {
  .react-tags__suggestions,
  .tribute-container {
    width: auto;
    overflow: hidden;
  }
}

.react-tags__suggestions {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin-top: 2px;
}

/* make the dropdown look like dashkit dropdowns */
.react-tags__suggestions ul {
  margin: 0px -1px;
}

.react-tags__suggestions ul,
.tribute-container ul {
  border: 1px solid #edf2f9;
  border-radius: 0.375rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  overflow: hidden;
  background: white;
}

.react-tags__suggestions li,
.tribute-container li {
  border-bottom: 1px solid #edf2f9;
  padding: 8px 10px;
}

.react-tags__suggestions li:hover,
.tribute-container li:hover {
  background: $soft-primary;
}

.react-tags__suggestions li.is-active,
.tribute-container li.is-active {
  background: $soft-primary;
}

// override bolding of tribute lits text
.tribute-container li span {
  font-weight: normal;
}
