@font-face {
  font-family: 'Cerebri Sans';
  src: url('../vendor/fonts/cerebrisans-italic.eot');
  src: url('../vendor/fonts/cerebrisans-italic.eot?#iefix') format('embedded-opentype'), url('../vendor/fonts/cerebrisans-italic.woff') format('woff'), url('../vendor/fonts/cerebrisans-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('../vendor/fonts/cerebrisans-mediumitalic.eot');
  src: url('../vendor/fonts/cerebrisans-mediumitalic.eot?#iefix') format('embedded-opentype'), url('../vendor/fonts/cerebrisans-mediumitalic.woff') format('woff'), url('../vendor/fonts/cerebrisans-mediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('../vendor/fonts/cerebrisans-semibolditalic.eot');
  src: url('../vendor/fonts/cerebrisans-semibolditalic.eot?#iefix') format('embedded-opentype'), url('../vendor/fonts/cerebrisans-semibolditalic.woff') format('woff'), url('../vendor/fonts/cerebrisans-semibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
