// Override variables
@import "variables";

// Main Bootstrap theme (Dashkit) and associated font css
@import "vendor/good-themes/dashkit/css/theme.css";

// Bootstrap slider
@import "../node_modules/bootstrap-slider/dist/css/bootstrap-slider.css";

// React image gallery
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";

// Spinners
@import "~spinkit/spinkit.min.css";

// Breakpoint styles and functions
@import "breakpoints";

// Survey styles
@import "survey";

// Various typography items (used mainly for icons)
@import "typography";

// Icon-specific css
@import "icons";

// Custom fonts
@import "fonts";

// Tribute.js (for @ mentions)
@import "~tributejs/tribute.css";

// Toasts
@import '~react-toastify/dist/ReactToastify.css';

// Custom styles (specific to Confirm)
@import "custom";
@import "react-tags-custom";
