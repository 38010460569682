// flip icon horizontally
.flip-horizontal {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.flip-vertical {
  transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
}

// Brand Colors
$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$flickr: #ff0084 !default;
$tumblr: #32506d !default;
$xing: #026466 !default;
$github: #4183c4 !default;
$stack-overflow: #fe7a15 !default;
$youtube: #b00 !default;
$dribbble: #ea4c89 !default;
$instagram: #517fa4 !default;
$pinterest: #cb2027 !default;
$vk: #45668e !default;
$yahoo: #400191 !default;
$behance: #1769ff !default;
$reddit: #ff4500 !default;
$vimeo: #aad450 !default;

// class to add to all brand buttons
.btn-brand {
  line-height: 0;
  width: 2.2em;
  background-color: #f0f0f0;
}

.btn-brand > i {
  position: relative;
  left: -4px;
}

.btn-brand:hover {
  color: #fff;
  background-color: #cccccc;
  border-color: #b3b3b3;
}

.btn-brand:focus, .btn-brand.focus {
  color: #fff;
  background-color: #cccccc;
  border-color: #b3b3b3;
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-brand.disabled, .btn-brand:disabled {
  color: #fff;
  background-color: #969696;
  border-color: #969696;
}

.btn-brand:not(:disabled):not(.disabled):active, .btn-brand:not(:disabled):not(.disabled).active,
.show > .btn-brand.dropdown-toggle {
  color: #fff;
  background-color: #b3b3b3;
  border-color: #969696;
}

.btn-brand:not(:disabled):not(.disabled):active:focus, .btn-brand:not(:disabled):not(.disabled).active:focus,
.show > .btn-brand.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

// Based on Core UI css/buttons.css
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.btn-facebook:focus, .btn-facebook.focus {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
}

.btn-twitter:focus, .btn-twitter.focus {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0087ba;
  border-color: #007ead;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}

.btn-linkedin {
  color: #fff;
  background-color: #4875b4;
  border-color: #4875b4;
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #3d6399;
  border-color: #395d90;
}

.btn-linkedin:focus, .btn-linkedin.focus {
  color: #fff;
  background-color: #3d6399;
  border-color: #395d90;
  box-shadow: 0 0 0 0.2rem rgba(99, 138, 191, 0.5);
}

.btn-linkedin.disabled, .btn-linkedin:disabled {
  color: #fff;
  background-color: #4875b4;
  border-color: #4875b4;
}

.btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #395d90;
  border-color: #365786;
}

.btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 138, 191, 0.5);
}

.btn-github {
  color: #fff;
  background-color: #4183c4;
  border-color: #4183c4;
}

.btn-github:hover {
  color: #fff;
  background-color: #3570aa;
  border-color: #3269a0;
}

.btn-github:focus, .btn-github.focus {
  color: #fff;
  background-color: #3570aa;
  border-color: #3269a0;
  box-shadow: 0 0 0 0.2rem rgba(94, 150, 205, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #4183c4;
  border-color: #4183c4;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #3269a0;
  border-color: #2f6397;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 150, 205, 0.5);
}
